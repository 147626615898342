<template>
  <en-dialog :model-value="modelValue" width="80%" title="回访列表" @close="$emit('update:model-value', false)">
    <template #operation>
      <en-button type="primary" link @click="operation.sms.click">发送短信</en-button>
      <en-button type="primary" link @click="operation.coupon.click">发送优惠券</en-button>
      <button-download
        :ajax="{ action: 'GET /enocloud/service/return/visit/export', params: (params) => (params.payload = table.$ajaxParams.payload) }"
      >
        导出
      </button-download>
    </template>

    <table-dynamic
      :height="600"
      code="SVRTVIFLD"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      pagination
      show-selectable
      :config="table.config"
      @selection-change="table.selection.change"
    >
      <template #OPERATION="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitQueryDto'] }">
        <button-delete v-if="row.bound === '已绑定'" content="确定解绑吗?" @click="table.operation.unbind.click(row)">微信解绑</button-delete>
        <button-ajax v-else link :method="table.operation.bind.click" :params="row">微信绑定</button-ajax>
      </template>
    </table-dynamic>
  </en-dialog>

  <send-coupon
    v-model="coupon.visible"
    :options="table.selection.data"
    :props="{ name: 'customerName', id: 'customerId' }"
    @confirm="table.get"
  ></send-coupon>

  <short-message-template
    v-model="sms.visible"
    :data="table.selection.data"
    :props="{ name: 'customerName', cellphone: 'customerCellphone' }"
  ></short-message-template>
</template>

<script lang="ts">
import { h } from 'vue'
import { EnMessage, EnMessageBox } from '@enocloud/components'
import { SendCoupon, ShortMessageTemplate } from '@enocloud/business'

export default factory({
  components: { SendCoupon, ShortMessageTemplate },

  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      operation: {
        coupon: {
          click() {
            if (!this.table.selection.data.length) return EnMessage.warning('请选择客户')
            this.coupon.visible = true
          }
        },
        sms: {
          click() {
            if (!this.table.selection.data.length) return EnMessage.warning('请选择客户')
            this.sms.visible = true
          }
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/return/visit',
            data: 'array',
            loading: true,
            pagination: true
          },
          unbind: {
            action: 'POST /enocloud/common/customer/:customerId/wechat/unbind'
          },
          bind: {
            action: 'GET /enocloud/common/customer/:customerId/wechat/bind/url'
          }
        },
        config: {
          SETTLEMENT_DATETIME: {
            header: { filter: { type: 'date', field: ['settlementStartDate', 'settlementEndDate'], props: { type: 'daterange' } } }
          },
          DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['dateStart', 'dateEnd'],
                props: { type: 'daterange' }
              }
            }
          },
          SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serviceSerialNo'
              }
            }
          },
          PLATE_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'plateNo'
              }
            }
          },
          VEHICLE_SPEC: {
            header: {
              filter: {
                type: 'text',
                field: 'vehicleSpec'
              }
            }
          },
          CUSTOMER_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'customerName'
              }
            }
          },
        
          
          SERVICE_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceCategoryId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/service/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/advisorteam/advisor',
                    params: (params, value) => (params.payload = { name: value, })
                  },
                  props: { label: 'user.name', value: 'user.name' },
                  remote: true
                }
              }
            }
          },
          SERVICE_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'serviceComment'
              }
            }
          },
          BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'branchIds',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          }
        },
        children: {
          operation: {
            bind: {
              async click(row: EnocloudServiceDefinitions['ServiceReturnVisitQueryDto']) {
                const res = await this.table.bind({ paths: [row.id] })
                return EnMessageBox({
                  title: row.customerName,
                  center: true,
                  showConfirmButton: false,
                  message: h('img', { src: res.data[0], width: 300, height: 300, class: 'm-auto' })
                })
              }
            },
            unbind: {
              async click(row: EnocloudServiceDefinitions['ServiceReturnVisitQueryDto']) {
                await this.table.bind({})
                return this.table.get()
              }
            }
          },
          selection: {
            data: [] as EnocloudServiceDefinitions['ServiceReturnVisitQueryDto'][],
            change(rows: EnocloudServiceDefinitions['ServiceReturnVisitQueryDto'][]) {
              this.table.selection.data = rows
            }
          }
        }
      },
      coupon: {
        visible: false
      },
      sms: {
        visible: false
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
